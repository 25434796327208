import React from 'react';
import styled from "styled-components";

// Components
import SVGSpriteContainer from './SVGSpriteContainer';

// Styled Components
const LogoContainer = styled.a`
  color: currentColor;
  display: inline-flex;
`

const LogoSVG = styled.svg`
  height: 2em;
  width: 8em;

  &, use {
    fill: currentColor;
  }
`

const LogoSVGSprite = () => (
  <SVGSpriteContainer>
    <symbol id="daycfo-logo" viewBox="0 0 119.7 30" >
      <path d="M16.2,8.2c-1.7-2-4.1-3-6.9-3C3.8,5.2,0,8.8,0,14.7c0,6,3.8,9.5,9.3,9.5c2.9,0,5.3-1,6.9-3v2.4h7.5V0h-7.5V8.2z M16.2,16.6c-1.2,1.6-3,2.2-4.7,2.2c-2.8,0-4.4-1.6-4.4-4.1c0-2.5,1.6-4.1,4.4-4.1c1.7,0,3.4,0.6,4.7,2.2V16.6z M41.1,8.2 c-1.7-2-4.1-3-6.9-3c-5.6,0-9.3,3.6-9.3,9.5c0,5.9,3.8,9.5,9.3,9.5c2.8,0,5.3-1,6.9-3v2.4h7.5V5.8h-7.5V8.2L41.1,8.2z M41.1,16.6 c-1.2,1.6-3,2.2-4.7,2.2c-2.8,0-4.4-1.6-4.4-4.1s1.6-4.1,4.4-4.1c1.7,0,3.4,0.6,4.7,2.2V16.6z M62.1,16L57.5,5.8h-7.7l8.6,16.9 L54.6,30h7.5l12-24.2h-7.6L62.1,16z M87.1,17.4c-1.8,0-3-0.9-3-2.6c0-1.7,1.2-2.6,3-2.6c1.1,0,2,0.4,2.5,1.2l1.6-0.8 c-0.7-1.1-2.2-2-4.1-2c-3,0-4.9,1.5-4.9,4.1c0,2.6,1.9,4.1,4.8,4.1c2,0,3.5-0.9,4.2-2l-1.6-0.8C89.1,17,88.2,17.4,87.1,17.4z M93,18.6h1.8v-2.9h5.1v-1.5h-5.1v-1.8h6v-1.5H93V18.6z M106.8,10.7c-3,0-4.9,1.5-4.9,4.1s1.9,4.1,4.9,4.1c3,0,4.9-1.5,4.9-4.1 C111.7,12.2,109.8,10.7,106.8,10.7z M106.8,17.4c-1.9,0-3.1-0.9-3.1-2.6s1.2-2.6,3.1-2.6c1.9,0,3.1,0.9,3.1,2.6 S108.7,17.4,106.8,17.4z M110.8,5.8H83.5c-4.9,0-8.9,4-8.9,8.9c0,4.9,4,8.9,8.9,8.9h27.3c4.9,0,8.9-4,8.9-8.9 C119.7,9.8,115.7,5.8,110.8,5.8z M110.8,21.9H83.5c-4,0-7.2-3.2-7.2-7.2c0-4,3.2-7.2,7.2-7.2h27.3c4,0,7.2,3.2,7.2,7.2 C118.1,18.7,114.8,21.9,110.8,21.9z" />
    </symbol>
  </SVGSpriteContainer>
)

const Logo = ({ children }) => {
  return (
    <>
      <LogoSVGSprite />
      <LogoContainer href="/">
        <LogoSVG><use xlinkHref="#daycfo-logo" /></LogoSVG>
      </LogoContainer>
    </>
  );
};

export default Logo;
