import React from 'react';
import styled from "styled-components";

const TitleContainer = styled.div`
  &, span {
    font-family: "termina";
    font-size: 0.625rem;
    font-weight: 600;
    letter-spacing: 0.1em;
    line-height: 1.4;
    margin-bottom: 0.875rem;
    text-transform: uppercase;
  }
`

const Title = ({ children, className }) => {
  return (
    <TitleContainer className={className}>{children}</TitleContainer>
  );
};

export default Title;
