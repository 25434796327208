import React from 'react';
import styled, { css } from "styled-components";
import { queryBelow } from '../../Global/utilities/MediaQuery';

// Components
import Logo from '../../Global/components/Logo';

// Styled Components
const LandingHeaderContainer = styled.div`
  align-items: center;
  color: currentColor;
  display: flex;
  justify-content: space-between;
  position: relativel
  width: 100%;
  z-index: 1;

  ${queryBelow("bigPhone", css`
    margin-bottom: 10vw;
  `)}
`

const PseudoButton = styled.div`
  font-family: "termina";
  font-weight: 700;
  font-size: 1.8vw;
  max-font-size: 1.2em;
  font-size: min(2.4vw, 1.2em);
  padding: 0.6em 1em 0.5em;
  border-radius: 100px;
  border: 2px solid currentColor;
`

const LandingHeader = ({ children }) => {
  return (
    <LandingHeaderContainer>
      <Logo />
      <PseudoButton>Coming Soon</PseudoButton>
    </LandingHeaderContainer>
  );
};

export default LandingHeader;
