import React from 'react';
import styled, { css } from "styled-components";
import { queryBelow } from '../../Global/utilities/MediaQuery';

// Components
import Title from '../../Global/components/Title';
import BusinessesEmailListSignup from '../../Global/components/BuisnessesEmailListSignup';
import ExecutivesEmailListSignup from '../../Global/components/ExecutivesEmailListSignup';

// Styled Components
const LandingFooterContainer = styled.div`
  color: currentColor;
  display: grid;
  gap: 2em;
  grid-template-columns: 1fr 20rem;
  justify-content: space-between;
  width: 100%;

  ${queryBelow("bigPhone", css`
    grid-template-columns: 1fr;
    margin-top: 10vw;
  `)}
`

const EmailFormContainer = styled.div`
  width: 100%;
  margin-left: auto;

  ${queryBelow("bigPhone", css`
    width: 100%;
  `)}
`

const LandingFooter = ({ activeTab, titleText, paragraphText }) => {
  const EmailFormComponent = activeTab == "for-businesses" ? BusinessesEmailListSignup : ExecutivesEmailListSignup;

  console.log(activeTab);

  return (
    <LandingFooterContainer>
      <div>
        <Title>{titleText}</Title>
        <p className="large no-margin-bottom">{paragraphText}</p>
      </div>
      <EmailFormContainer>
        <EmailFormComponent />
      </EmailFormContainer>
    </LandingFooterContainer>
  );
};

export default LandingFooter;
