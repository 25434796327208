import React from 'react';
import styled, { css } from "styled-components";
import { callout, blackPoint } from '../../Global/utilities/Colors';
import { queryAbove, queryBelow } from '../../Global/utilities/MediaQuery';

// Components
import SVGSpriteContainer from '../../Global/components/SVGSpriteContainer';

// Styled Components
const LandingTabNavContainer = styled.div`
  background-color: ${callout};
  display: grid;
  grid-column: 2;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  position: relative;

  ${queryBelow("bigPhone", css`
    background-color: #FFFFFF;
    grid-column: 1;
    height: auto;
  `)}

  &.for-executives {
    background-color: ${blackPoint};
    grid-column: 1;
    grid-row: 1;
  }
`

const Tab = styled.div`
  align-items: center;
  background-color: #0F0C03;
  color: #0F0C03;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 4em;

  ${queryBelow("bigPhone", css`
    width: 100%;
  `)}

  &.callout {
    background-color: #F4B393;
  }

  &.active {
    z-index: 1;

    &.for-executives-tab {
      border-right: 2px solid rgba(255,255,255,0.2);

      ${queryBelow("bigPhone", css`
        border-right: none;
      `)}
    }
  }

  &:first-of-type {
    background-color: #0F0C03;
    border-left: 2px solid rgba(255,255,255,0.2);
    border-radius: 0 24px 24px 0;
    color: #FFFFFF;

    ${queryBelow("bigPhone", css`
      border-left: none;
      border-radius: 0 24px 0 0;
    `)}
  }

  svg {
    bottom: 0;
    height: 101%;
    max-width: 5em;
    position: absolute;
    width: 100%;

    &.callout {
      &, use {
        fill: #F4B393;
      }
    }

    &.dark {
      &, use {
        fill: #0F0C03;
      }
    }

    &.right {
      right: -3.2em;
    }

    &.left {
      left: -4.3em;
    }

    ${queryAbove("bigPhone", css`
      display: none;
    `)}
  }
`

const TabText = styled.a`
  color: currentColor;
  font-family: "termina";
  font-weight: 800;
  font-size: 1.2em;
  transform: rotate(90deg);
  padding: 1.1em 2em 1em;

  ${queryBelow("bigPhone", css`
    font-size: 0.8em;
    transform: rotate(0deg);
  `)}

  span {
    display: inline-block;
    text-align: center;
    width: 16em;

    ${queryBelow("bigPhone", css`
      width: 100%;
    `)}
  }
`

const TabSVGShapes = () => (
  <SVGSpriteContainer>
    <symbol id="tab-right" viewBox="0 0 61.5 32">
      <path d="M0,0c34,0,27.5,32,61.5,32H0V0z" />
    </symbol>

    <symbol id="tab-left" viewBox="0 0 61.5 32">
      <path d="M61.5,32H0C34,32,27.6,0,61.5,0V32z" />
    </symbol>
  </SVGSpriteContainer>
)


const LandingTabNav = ({ activeTab }) => {
  return (
    <LandingTabNavContainer className={activeTab}>
      <TabSVGShapes />
      <Tab className={activeTab === 'for-businesses' ? 'for-businesses-tab active' : 'for-businesses-tab'}>
        <TabText href="/for-businesses"><span>For Businesses</span></TabText>
        <svg className="dark right"><use xlinkHref="#tab-right" /></svg>
      </Tab>
      <Tab className={activeTab === 'for-executives' ? 'for-executives-tab active callout' : 'for-executives-tab callout'}>
        <svg className="left callout"><use xlinkHref="#tab-left" /></svg>
        <TabText href="/for-executives"><span>For Executives</span></TabText>
      </Tab>
    </LandingTabNavContainer>
  );
};

export default LandingTabNav;
