import React from 'react';
import styled from "styled-components";

const SVGSymbols = styled.svg`
  display: none;
  height: 0;
  width: 0;
  visibility: hidden;
`

const SVGSpriteContainer = ({ children }) => {
  return (
    <SVGSymbols>{children}</SVGSymbols>
  );
};

export default SVGSpriteContainer;
