import React, { useState } from 'react';
import styled, { css } from "styled-components";
import { blackPoint, whitePoint } from '../../Global/utilities/Colors';
import { queryBelow } from '../../Global/utilities/MediaQuery';

// Images
import businessLandingImage from '../../../../assets/images/dcfo-biz.webp';

// Components
import LandingTabNav from './LandingTabNav';
import LandingHeader from './LandingHeader';
import LandingFooter from './LandingFooter';
import Title from '../../Global/components/Title';

// Styled Components
const LandingOuterContainer = styled.div`
  border-radius: 24px;
  display: grid;
  grid-template-columns: 1fr 8em;
  height: max(calc(100vh - 4vw), 56em);
  margin: 2vw;
  overflow: hidden;
  width: calc(100vw - 4vw);

  ${queryBelow("bigPhone", css`
    border-radius: 0;
    grid-template-columns: 1fr;
    height: 100%;
    margin: 0;
    width: 100%;
  `)}
`

const LandingCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${whitePoint};
  background-color: ${blackPoint};
  height: 100%;
  width: 100%;
  padding: 4vw 6vw;
  overflow: hidden;
  grid-row: 1;

  ${queryBelow("bigPhone", css`
    grid-row: 2;
    padding: 10vw 6vw;
  `)}
`

const LandingCardContent = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const LandingCardBody = styled.div`
  align-items: center;
  color: ${whitePoint};
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;

  ${queryBelow("bigPhone", css`
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  `)}
`

const LandingCardBodyImage = styled.img`
  position: relative;
  max-width: 56em;
  width: 140%;
  left: -30%;

  ${queryBelow("bigPhone", css`
    left: -10%;
  `)}
`

const LandingCardBodyText = styled.div`
  width: 120%;
  z-index: 1;

  ${queryBelow("bigPhone", css`
    width: 100%;
  `)}
`

const LandingH1 = styled.h1`
  font-family: "termina";
  font-weight: 700;
  line-height: 115%;
  letter-spacing: -0.04em;
  font-size: min(4em, 10vw);
`

const LandingForBusinesses = (props) => {
  const activeTab = 'for-businesses';
  // const [name, setName] = useState(props.name);

  return (
    <LandingOuterContainer>
      <LandingTabNav activeTab={activeTab} />
      <LandingCard>
        <LandingCardContent>
          <LandingHeader />
          <LandingCardBody>
            <LandingCardBodyText>
              <Title>Find your CFO for the day</Title>
              <LandingH1 className="color-callout">Top-tier fractional CFOs on demand.</LandingH1>
            </LandingCardBodyText>
            <LandingCardBodyImage src={businessLandingImage} />
          </LandingCardBody>
          <LandingFooter
            activeTab={activeTab}
            titleText="Express Interest"
            paragraphText="Leave us your email to be the first to know when we launch."
          />
        </LandingCardContent>
      </LandingCard>
    </LandingOuterContainer>
  );
};

export default LandingForBusinesses;
