import ReactOnRails from 'react-on-rails';

import LandingForBusinesses from '../bundles/Landing/components/LandingForBusinesses';
import LandingForExecutives from '../bundles/Landing/components/LandingForExecutives';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  LandingForBusinesses,
  LandingForExecutives,
});
