import React, { useState } from 'react';
import styled, { css } from "styled-components";
import { callout, blackPoint, whitePoint } from '../../Global/utilities/Colors';
import { queryAbove, queryBelow } from '../../Global/utilities/MediaQuery';

// Images
import businessLandingImage from '../../../../assets/images/dcfo-exec-landing-image.webp';

// Components
import LandingTabNav from './LandingTabNav';
import LandingHeader from './LandingHeader';
import LandingFooter from './LandingFooter';
import Title from '../../Global/components/Title';

// Styled Components
const LandingOuterContainer = styled.div`
  border-radius: 24px;
  display: grid;
  grid-template-columns: 8em 1fr;
  height: max(calc(100vh - 4vw), 66em);
  margin: 2vw;
  overflow: hidden;
  width: calc(100vw - 4vw);

  ${queryBelow("bigPhone", css`
    border-radius: 0;
    grid-template-columns: 1fr;
    height: 100%;
    margin: 0;
    width: 100%;
  `)}
`

const LandingCard = styled.div`
  background-color: ${callout};
  color: ${blackPoint};
  display: flex;
  flex-direction: column;
  grid-row: 1;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  padding: 4vw 6vw;
  width: 100%;

  ${queryBelow("bigPhone", css`
    grid-row: 2;
    padding: 10vw 6vw;
  `)}
`

const LandingCardContent = styled.div`
  color: currentColor;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const LandingCardBody = styled.div`
  align-items: center;
  color: ${blackPoint};
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;

  ${queryBelow("bigPhone", css`
    align-items: flex-start;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  `)}
`

const LandingCardBodyImage = styled.img`
  grid-column: 1;
  grid-row: 1;
  left: -25%;
  max-width: 46em;
  position: relative;
  top: -5%;
  width: 130%;

  ${queryBelow("bigPhone", css`
    grid-auto-flow: row;
    grid-row: 2;
    left: 0;
    margin-top: -15%;
    top: 0;
    width: 100%;
  `)}
`

const LandingCardBodyText = styled.div`
  grid-column: 2;
  grid-row: 1;
  width: 100%;
  z-index: 1;

  ${queryBelow("bigPhone", css`
    grid-column: 1;
    width: 100%;
  `)}
`

const ExecLandingTitle = styled(Title)`
  ${queryAbove("bigPhone", css`
    text-align: right;
  `)}
`

const LandingH1 = styled.h1`
  color: ${blackPoint};
  font-family: "termina";
  font-weight: 700;
  line-height: 115%;
  letter-spacing: -0.04em;
  font-size: min(4em, 10vw);

  ${queryAbove("bigPhone", css`
    text-align: right;
  `)}
`

const LandingForExecutives = (props) => {
  const activeTab = 'for-executives';
  // const [name, setName] = useState(props.name);

  return (
    <LandingOuterContainer>
      <LandingTabNav activeTab={activeTab} />
      <LandingCard>
        <LandingCardContent>
          <LandingHeader />
          <LandingCardBody>
            <LandingCardBodyText>
              <ExecLandingTitle>Find your Client for the day</ExecLandingTitle>
              <LandingH1 className="color-callout">Help businesses succeed on your terms.</LandingH1>
            </LandingCardBodyText>
            <LandingCardBodyImage src={businessLandingImage} />
          </LandingCardBody>
          <LandingFooter
            activeTab={activeTab}
            titleText="Express Interest"
            paragraphText="Leave us your email to be the first to know when we launch."
          />
        </LandingCardContent>
      </LandingCard>
    </LandingOuterContainer>
  );
};

export default LandingForExecutives;
