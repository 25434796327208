import React from "react";
import styled from "styled-components";
import MailchimpSubscribe from "react-mailchimp-subscribe"

// Components
import SVGSpriteContainer from "./SVGSpriteContainer";
import Title from "./Title";

// Styled Components
const EmailListSignupContainer = styled.div`
  width: 100%;
`

const EmailListSignupForm = styled.div`
  align-items: center;
  border-radius: 100em;
  border: 2px solid currentColor;
  display: flex;
`

const EmailListSignupInput = styled.input`
  background-color: rgba(0,0,0,0);
  color: currentColor;
  flex: 1;
  font-family: "N27-Regular";
  font-size: 1em;
  padding: 1em;
`

const EmailListSignupButton = styled.button`
  align-items: center;
  border-radius: 50em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 0.4em;
  padding: 1em;

  svg {
    height: 1.5em;
    position: relative;
    right: -1px;
    width: 1.5em;

    &, use {
      fill: #0F0C03;
    }
  }
`

// SVG Sprite
const ButtonSVG = () => (
  <SVGSpriteContainer>
    <symbol id="chevron-right" viewBox="0 0 10 18" >
      <path d="M1,18c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4L7.6,9L0.3,1.7c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l8,8 c0.4,0.4,0.4,1,0,1.4l-8,8C1.5,17.9,1.3,18,1,18z" />
    </symbol>
  </SVGSpriteContainer>
)

// Form
const CustomForm = ({ status, message, onValidated }) => {
  let email, name;
  const submit = () =>
    email &&
    // name &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
      // NAME: name.value
    });

  return (
    <EmailListSignupContainer>
      {status !== "sending" && status !== "error" && status !== "success" && (
        <Title>Stay Up To Date</Title>
      )}
      {status === "sending" && <Title>sending...</Title>}
      {status === "error" && (
        <Title><span dangerouslySetInnerHTML={{ __html: message }} /></Title>
      )}
      {status === "success" && (
        <Title><span dangerouslySetInnerHTML={{ __html: message }} /></Title>
      )}
      {/* <input
        style={{ fontSize: "2em", padding: 5 }}
        ref={node => (name = node)}
        type="text"
        placeholder="Your name"
      /> */}

      <EmailListSignupForm>
        <EmailListSignupInput
          ref={node => (email = node)}
          type="email"
          placeholder="Enter your email"
        />

        <EmailListSignupButton onClick={submit}>
          <ButtonSVG />
          <svg><use xlinkHref="#chevron-right" /></svg>
        </EmailListSignupButton>
      </EmailListSignupForm>
    </EmailListSignupContainer>
  );
};

const BusinessesEmailListSignup = ({ titleText }) => {
  const url = "https://fun.us21.list-manage.com/subscribe/post?u=b9ba920825c83015fc99a9bc9&amp;id=b6ab834b87&amp;f_id=005861e1f0";

  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <CustomForm
          status={status}
          message={message}
          onValidated={formData => subscribe(formData)}
        />
      )}
    />
  );
}

export default BusinessesEmailListSignup;
